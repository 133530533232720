<template>
  <div class="w-full">
    <h3
      class="heading-text relative text-3xl font-semibold text-black inline ml-3"
    >
      {{ headingText }}
    </h3>
    <a
      :href="catalogUrl"
      class="float-right border-2 border-blue-700 rounded-lg text-blue-700 px-10 py-1.5 font-semibold uppercase hover:bg-blue-700 hover:text-white"
    >
      View more experiences
    </a>
  </div>
</template>

<script>
export default {
  name: "Page Heading",
  components: {},
  props: {
    headingText: {
      type: String,
      required: true,
    },
    routeName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      catalogUrl: process.env.VUE_APP_EMP_URL + "/demos/catalog",
    };
  },
  methods: {
    goBackToRoute() {
      this.$router.replace({
        name: this.routeName,
      });
    },
  },
};
</script>

<style scoped></style>

<template>
  <div
    class="text-center flex flex-col px-4 md:text-left md:flex-row bg-nColorFillGray border border-nColorStrokeGray rounded-xl items-center md:justify-between md:px-4 py-2 pb-6 md:pb-2 mb-4 md:mb-0"
    id="messageModal"
    v-if="!submitted"
  >
    <div class="text-xl font-semibold w-full">
      <div class="text-white flex flex-row">
        <div class="hidden md:block w-1/12">
          <lottie-animation
            path="lotties/feedback.json"
            :loop="true"
            :autoPlay="true"
            :speed="1"
            :width="100"
            :height="100"
          />
        </div>
        <div class="block md:hidden w-1/12">
          <lottie-animation
            path="lotties/feedback.json"
            :loop="true"
            :autoPlay="true"
            :speed="1"
            :width="40"
            :height="40"
          />
        </div>
        <div
          class="flex items-center text-black text-left text-base md:text-3xl ml-6 md:ml-14 w-11/12"
        >
          Do you like this experience?
        </div>
      </div>
    </div>
    <div
      class="mt-4 md:mt-0 md:ml-2 w-8/12 grid justify-start md:justify-end md:mr-2"
    >
      <form @submit.prevent="submit()" class="flex flex-wrap items-center">
        <div class="like-exp md:flex md:flex-wrap md:justify-between">
          <div class="flex like-options w-full">
            <div class="w-1/3 mr-4">
              <input
                type="radio"
                required
                value="happy"
                class="opacity-0 pointer-events-none"
                name="like"
                id="like_happy"
                v-model="like"
              />
              <label for="like_happy" @click.prevent="assignLike('happy')">
                <img
                  :class="{ 'filter-gray': like !== 'happy' }"
                  class="w-10 md:w-16 inline-block cursor-pointer"
                  :src="
                    require('@/assets/images/sample-catalog/feedback_happy.svg')
                  "
                  alt=""
                />
              </label>
            </div>
            <div class="w-1/3 mr-4">
              <input
                type="radio"
                required
                class="opacity-0 pointer-events-none"
                value="neutral"
                name="like"
                id="like_neutral"
                v-model="like"
              />
              <label for="like_neutral" @click.prevent="assignLike('neutral')"
                ><img
                  :class="{ 'filter-gray': like !== 'neutral' }"
                  class="w-10 md:w-16 inline-block cursor-pointer"
                  :src="
                    require('@/assets/images/sample-catalog/feedback_neutral.svg')
                  "
                  alt=""
              /></label>
            </div>
            <div class="w-1/3">
              <input
                type="radio"
                required
                value="nothappy"
                class="opacity-0 pointer-events-none"
                name="like"
                id="like_nothappy"
                v-model="like"
              />
              <label
                for="like_nothappy"
                @click.prevent="assignLike('nothappy')"
              >
                <img
                  :class="{ 'filter-gray': like !== 'nothappy' }"
                  class="w-10 md:w-16 inline-block cursor-pointer"
                  :src="require('@/assets/images/sample-catalog/smile_sad.svg')"
                  alt=""
                />
              </label>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div
    class="text-center flex flex-col px-4 md:text-left md:flex-row rounded-lg md:items-center md:justify-between md:px-4 py-2 bg-nColorBlue mb-4"
    id="messageModal"
    v-else
  >
    <div class="text-xl font-semibold">
      <div class="text-white flex flex-row">
        <lottie-animation
          path="lotties/thankyou.json"
          :loop="true"
          :autoPlay="true"
          :speed="1"
          :width="100"
          :height="100"
        />
        <div class="grid text-left items-center text-lg md:text-3xl ml-6">
          Thank you for your valuable feedback!
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
  name: "ExpFeedback",
  components: { LottieAnimation },
  props: {
    exp: { type: String, required: true },
  },
  data() {
    return { like: null, play: null, loading: false, submitted: false };
  },
  methods: {
    assignLike(like) {
      this.like = like;
      this.submit();
    },
    assignPlay(play) {
      this.play = play;
    },
    submit() {
      this.loading = true;
      ApiService.post(apiResource.demo.addFeedback, {
        exp: this.exp,
        like: this.like,
      })
        .then(() => {
          this.submitted = true;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.filter-gray {
  filter: grayscale(100%);
}
.filter-gray:hover {
  filter: grayscale(0);
}
</style>

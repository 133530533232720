<template>
  <div
    class="flex px-2 py-4 items-center justify-around fixed top-0 left-0 md:hidden z-10 bg-blue-900 w-full border-b border-blue-900"
  >
    <div class="w-3/4 items-center flex">
      <h3 class="relative text-lg font-semibold text-white inline ml-2 w-full">
        {{ headingText }}
      </h3>
    </div>
    <div class="w-2/4 text-black">
      <a
        :href="catalogUrl"
        class="inline-block bg-nColorBlue text-white px-2 py-2 rounded-lg text-xs border-progressActive font-bold tracking-wider"
      >
        View more experiences
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileHeader",
  components: {},
  props: {
    headingText: {
      type: String,
      required: true,
    },
    routeName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      catalogUrl: process.env.VUE_APP_EMP_URL + "/demos/catalog",
    };
  },
  methods: {
    goBackToRoute() {
      this.$router.replace({
        name: this.routeName,
      });
    },
  },
};
</script>

<style scoped></style>

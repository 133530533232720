export default {
  ttal: "obk-ttal",
  scavengerHunt: "obk-scavenger-hunt",
  gtm: "obk-gtm",
  welcomeVideo: "obk-welcome-video",
  inductionVideo: "obk-induction-video",
  welcome360: "obk-welcome-360",
  documentLibrary: "obk-document-library",
  evp: "obk-evp",
  candidateExpSurvey: "obk-candidate-exp-survey",
  nha: "obk-new-hire-announcement",
  pulseOneWeek: "obk-pulse-check-1-week",
  managerNPS: "obk-manager-nps",
  demoJigsaw: "obk-jigsaw",
  demoScratchCard: "scratchcard",
  demoBirthday: "obk-birthday",
  jigsaw: "tools-jigsaw",
  scratchCard: "tools-scratch-card",
  spinTheWheel: "tools-spin-the-wheel",
  celebrate: "tools-celebrate",
  evpOptions: {
    evpoption1: "Radial", // RADIAL
    evpoption2: "Visual", // VISUAL
    evpoption3: "Bubbles", // BUBBLES
  },
  nhaOptions: {
    nhaoption1: "Option1",
    nhaoption2: "Option2",
    nhaoption3: "Option3",
    nhaoption4: "Option4",
  },
  welcome360Options: {
    w360option1: "City", // CITY
    w360option2: "Space", // SPACE
    w360option3: "IndoorStudio", // INDOOR STUDIO
    w360option4: "Forest", // FOREST
  },
  celebrationOptions: {
    cake: "bday-cake",
    card: "bday-card",
  },
  communication: {
    scratchCard: "scratchcard",
    spinthewheel: "spinthewheel",
  },
  expPaths: {
    "obk-ttal": "ObkThreeTruths",
    "obk-jigsaw": "ObkJigsaw",
    "obk-scavenger-hunt": "ObkScavengerHunt",
    "obk-gtm": "ObkGTM",
    "obk-welcome-video": "ObkWelcomeVideo",
    "obk-induction-video": "ObkInductionVideo",
    "obk-welcome-kit-ar": "ObkWelcomeKitAR",
    "obk-document-library": "ObkDocumentLibrary",
    "obk-evp": "ObkEVP",
    "obk-new-hire-announcement": "ObkNHA",
    "obk-pulse-check-1-week": "ObkPulse1Week",
  },
  expSamplePaths: {
    "obk-welcome-video": "OpenEventWelcomeVideo",
    "obk-evp": "OpenEventEVP",
    "obk-birthday": "OpenEventBirthday",
    "obk-induction-video": "OpenEventInductionVideo",
    "obk-new-hire-announcement": "OpenEventNHA",
    "obk-document-library": "OpenEventDocumentLibrary",
    "obk-welcome-360": "OpenEventWelcome360",
    "obk-scavenger-hunt": "OpenEventScavenger",
    "obk-ttal": "OpenEventTTAL",
    "obk-gtm": "OpenEventGTM",
    "obk-pulse-check-1-week": "OpenEventPulseOneWeek",
    "obk-jigsaw": "OpenEventJigsaw",
    scratchcard: "OpenEventScratchCard",
    spinthewheel: "OpenEventSpinTheWheel",
  },
  evpSlugs: {
    onboarding: {
      slug: "onboarding",
      label: "Onboarding",
      icon: "images/evp/icons/onboarding.svg",
    },
    compensation: {
      slug: "compensation",
      label: "Compensation",
      icon: "images/evp/icons/compensation.svg",
    },
    benefits: {
      slug: "benefits",
      label: "Benefits",
      icon: "images/evp/icons/benefits.svg",
    },
    career: {
      slug: "career",
      label: "Career",
      icon: "images/evp/icons/career.svg",
    },
    work_environment: {
      slug: "work_environment",
      label: "Work Environment",
      icon: "images/evp/icons/environment.svg",
    },
    culture: {
      slug: "culture",
      label: "Culture",
      icon: "images/evp/icons/culture.svg",
    },
    organization: {
      slug: "organization",
      label: "Organization",
      icon: "images/evp/icons/organization.svg",
    },
    offboarding: {
      slug: "offboarding",
      label: "Exit / Offboarding",
      icon: "images/evp/icons/exit.svg",
    },
    retiral_and_benefits: {
      slug: "retiral_and_benefits",
      label: "Retiral And Benefits",
      icon: "images/evp/icons/retiral.svg",
    },
  },
};

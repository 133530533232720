<template>
  <div class="banner-image">
    <div class="exp-image rounded-lg relative md:mb-10 md:bg-black">
      <div class="flex justify-center">
        <div id="video-element" class="md:w-1/2">
          <video
            id="video"
            controls
            :onended="exitFullscreen"
            preload="metadata"
            class="rounded-lg md:rounded-none"
          >
            <source :src="videoLink + '#t=2'" type="video/mp4" />
            Sorry, your browser doesn't support embedded videos.
          </video>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    videoLink: {
      type: String,
      required: true,
    },
  },
  mounted() {
    var videoElement = document.getElementById("video-element");
    if (videoElement) {
      videoElement.addEventListener(
        "click",
        function() {
          if (videoElement.requestFullscreen) {
            videoElement.requestFullscreen();
          } else if (videoElement.msRequestFullscreen) {
            videoElement.msRequestFullscreen();
          } else if (videoElement.mozRequestFullScreen) {
            videoElement.mozRequestFullScreen();
          } else if (videoElement.webkitRequestFullScreen) {
            videoElement.webkitRequestFullScreen();
          }
        },
        false
      );
    }
    var videoTag = document.getElementById("video");
    videoTag.addEventListener(
      "play",
      () => {
        videoTag.currentTime = 0;
      },
      { once: true }
    );
  },
  methods: {
    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    },
  },
};
</script>

<style scoped>
video::-webkit-media-controls-fullscreen-button {
  display: none;
}
</style>

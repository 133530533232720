<template>
  <div class="bg-white p-6 text-center text-black">
    <p class="text-sm md:text-base">
      © Texperia Techsolutions Pvt Ltd. All rights reserved.
    </p>
    <a
      :href="contactUrl"
      target="_blank"
      class="text-sm md:text-base block underline"
    >
      Contact us
    </a>
  </div>
</template>

<script>
export default {
  name: "DesktopFooter",
  components: {},
  data() {
    return {
      sidebar: false,
      contactUrl: process.env.VUE_APP_EMP_URL + "/contact",
    };
  },
  methods: {},
};
</script>

<style scoped></style>
